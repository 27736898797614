// title footer

@keyframes loaderAnim {
    to {
        opacity: 1;
        transform: scale3d(0.5, 0.5, 1);
    }
}

.unbutton {
    background: none;
    border: 0;
    padding: 0;
    margin: 0;
    font: inherit;
    cursor: pointer;
}

.unbutton:focus {
    outline: none;
}

.oh {
    position: relative;
    overflow: hidden;
}

.oh__inner {
    will-change: transform;
    display: inline-block;
}

.line {
    transform-origin: 0;
    white-space: nowrap;
    will-change: transform;
}

.contentv5 {
    line-height: 1;
    position: relative;
    z-index: 1;
    grid-row: 2;
}

.preview-open .contentv5 {
    pointer-events: none;
}

.demo-3.preview-open .contentv5 {
    z-index: 3;
}

.contentv5__item {
    max-width: 500px;

    &:not(:last-child) {
        margin-bottom: 40px;
    }
}

.contentv5__item-img-wrap {
    overflow: hidden;
    position: relative;
    width: 100%;
    aspect-ratio: 0.75;
    cursor: pointer;
    will-change: transform;
}

.demo-3 .preview__item .contentv5__item-img-wrap {
    width: auto;
    height: 100%;
    aspect-ratio: auto;
}

.contentv5__item-img {
    background-size: cover;
    background-position: 50% 50%;
    width: 100%;
    height: 100%;
    will-change: transform;
}

.contentv5__overlay {
    background: var(--color-bg-overlay);
    position: fixed;
    top: calc(50% - 75vmax);
    left: calc(50% - 75vmax);
    width: 150vmax;
    height: 150vmax;
    pointer-events: none;
    will-change: transform;
    z-index: 3;
}

.overlay__inner {
    background: var(--color-bg-overlay);
    width: 100%;
    height: 100%;
    position: relative;
}

@media screen and (min-width: 53em) {
    .contentv5 {
        display: flex;
        align-items: center;
        align-content: center;
        grid-area: 1 / 1 / -1 / -1;
    }
}